import * as React from "react";
function isAssoc(collection) {

    if( null === collection )
        return false;

    return isNaN(parseInt(Object.keys(collection)[0]));
}

function parsedObjectToId( item ){

    if( isAssoc( item ) ){
        return item.id;
    }

    return item;
}

class HttpResponse {

    _request: XMLHttpRequest;

    constructor( request: XMLHttpRequest|null = null ){
        this.setRequest(request);
    }

    public setRequest( request: XMLHttpRequest ){
        this._request = request;
        return this;
    }

    public getData(): Object | string{

        try{
            return JSON.parse(this._request.responseText);
        } catch(e){
            console.error(e.title);
        }

        return this._request.responseText;
    }

    public getStatus(): number {
        return this._request.status
    }

    public getStatusText(): string {
        return this._request.statusText
    }

    public getReadyState(): number {
        return this._request.readyState;
    }
}
const response = new HttpResponse();






type RequestType = 'POST' | 'GET'
type RequestCollectionType = FormData;
type ResponseCallbackArgs = <T>(data: any) => AISHTTPRequest;
type ResponseCallbackProgressArgs = <T>(total: number, loaded: number, percentage: number ) => AISHTTPRequest;

class AISHTTPRequest {

    private _method: string;
    private _url: string;
    private _collection: FormData;
    private _request: XMLHttpRequest;
    private _response: HttpResponse;
    private _then: Function;
    private _progress: Function;
    private _catch: Function;
    private _beforeSend: any;
    private _linearProcess: Function;

    constructor(){

    }

    public beforeSend( callbackFn: Function ): this{
        if( undefined !== callbackFn && "function" === typeof callbackFn ){
            this._beforeSend = callbackFn;
        }
        return this;
    }

    public abort(){
        this._request.abort();
        return this;
    }

    public setLinearProcess( linearProcess ){
        console.log('linearProcess',linearProcess);
        this._linearProcess = linearProcess;
        return this;
    }

    public send( url: string, method: RequestType, collection?: RequestCollectionType ): AISHTTPRequest{


        if(  "function" === typeof this._beforeSend ){
            this._beforeSend();
        }


        if(  "function" === typeof this._linearProcess ){
            this._linearProcess();
        }

        if( /^\//.test( url ) === false ){
            throw `\n [!] Please check the url -> ${url}, \n [!] Url must start with '/'\n [!] correct url like: /${url}`;
        }

        if( !(collection instanceof FormData) ){

            parser.render( collection, collection => {
                req( this, collection );
            })
        } else {

            req( this, collection);
        }

        function req( _class, collection ){

            console.log( "Collection", parser.toObject(collection) );

            if(!(collection instanceof FormData)){
                throw new Error("Collection must be instance of FormData!");
            }

            try{

                _class._method = method;
                _class._url = url;
                _class._request = new XMLHttpRequest();
                _class._request.loadstart = () => {
                    _class._beforeSend();
                    _class._linearProcess()(1);
                }
                _class._request.loadend = () => {

                }
                _class._request.onreadystatechange = ( event ) => {

                    console.log('onreadystatechange',_class._request.readyState);

                    if (_class._request.readyState == 4 ){

                        _class._response = response.setRequest(_class._request);

                        if( _class._request.status === 200 ){

                            console.log('HTTPRequest Is Loaded', _class._request.status );

                            if (_class._then !== undefined && typeof _class._then === "function") {
                                _class._then( _class._response.getData() );
                            }
                            return ;
                        }

                        if( typeof  _class._catch === 'function'){
                            const data = _class._response.getData();
                            console.error('AISHTTPRequest 168',data);

                            const response = {...data,
                                code:_class._request.status,
                                message: _class._request.status + " " + (data.title || _class._request.statusText),
                                severity: data.severity || "error"
                            };

                            _class._catch(response);
                        }

                    }

                };
                _class._request.onprogress = ( event ) => {
                    var percentComplete = (event.loaded / event.total) * 100;

                    console.warn('onprogress', event, event.lengthComputable, event.loaded, event.total, _class._request );
                    if( typeof _class._progress === "function"){
                        var percentComplete = (event.loaded / event.total) * 100;
                        _class._progress( event.total, event.loaded, percentComplete )
                    }
                    if( typeof _class._linearProcess === 'function'){
                        _class._linearProcess(percentComplete);
                    }
                };
                _class._request.open( _class._method, _class._url, true );


                // const f = new FormData();
                // f.append('name', 'Suleyman');

                _class._request.send( collection );
            }
            catch (e) {
                if( typeof  _class._catch === 'function'){
                    _class._catch(e);
                }
                console.error(e.title);
            }

        }


        return this;

    }


    public then( callbackFn: ResponseCallbackArgs ): AISHTTPRequest {

        if ( undefined !== callbackFn && typeof callbackFn === "function" ){
            this._then = callbackFn
        }

        return this;
    }

    public progress( callbackFn: ResponseCallbackProgressArgs ): AISHTTPRequest {

        if ( undefined !== callbackFn && typeof callbackFn === "function" ){
            this._progress = callbackFn;
        }

        return this;
    }

    public catch( callbackFn: ResponseCallbackArgs ): this {
        if ( undefined !== callbackFn && typeof callbackFn === "function" ){
            this._catch = callbackFn
        }

        return this;
    }
}

const http = new AISHTTPRequest();
export { http as aisRequest }
export default AISHTTPRequest;

type ParserCallbackType = <T>( collection: FormData)=> void;

class AISCollectionParser {

    private itemType( item ): number{
        switch( typeof item ){
            case "number":
                return this.TYPE_NUMBER;
            case "string":
                try{ JSON.parse(item); return this.TYPE_JSON;
                } catch( e ){ return this.TYPE_STRING; }
            case "object":
                if( Array.isArray(item) ){
                    return this.TYPE_ARRAY
                }
                return this.TYPE_OBJECT;

            case "boolean":
                return this.TYPE_BOOLEAN
        }
    }

    private isObject( item ){ return this.itemType( item ) === this.TYPE_OBJECT; }
    private isArray( item ){ return this.itemType( item ) === this.TYPE_ARRAY; }
    private isNumber( item ){ return this.itemType( item ) === this.TYPE_NUMBER; }
    private isString( item ){ return this.itemType( item ) === this.TYPE_STRING; }
    private isBoolean( item ){ return this.itemType( item ) === this.TYPE_BOOLEAN; }
    private isJson( item ){ return this.itemType( item ) === this.TYPE_JSON; }

    private TYPE_OBJECT: number = 1;
    private TYPE_ARRAY: number = 2;
    private TYPE_STRING: number = 3;
    private TYPE_NUMBER: number = 4;
    private TYPE_BOOLEAN: number = 5;
    private TYPE_JSON: number = 6;

    private _formData: FormData;

    public toJson( collection: FormData ): string{
        return JSON.stringify( this.toObject( collection ) );
    }

    public toObject( collection: FormData ): object{

        var object = {};
        collection.forEach(function(value, key){
            object[key] = value;
        });

        return  object;

    }

    /**
     * birthdayAt to birthday_at */
    keyFormatter(keyText){

        console.warn('keyText',keyText);
        return (keyText.match(/[A-Za-z0-9]([a-z|0-9])+/g)).map(item=>{
            return item.toLowerCase();
        }).join("_")

    }

    public render(_collection: Array<{ [p: string]: any }> , callbackFn: ParserCallbackType | null = null): FormData{

        if( _collection === null )
            callbackFn(null);

        const _formData = new FormData();


        console.log('_collection', _collection);

        // Base collection is absolute Object
        if( this.isObject( _collection ) ) {

            /*{
                id: 1,
                activity: {id:1, name:"XY"},
                qualifications: [],
                qualifications: [[{id:3}, {id:4}]],
                birthday_at: new Date()

             */

            for (const k of Object.keys(_collection)) {


                const formattedKey = this.keyFormatter(k);


                if (_collection[k] instanceof Date) {

                    _formData.append(formattedKey, [
                            _collection[k].getFullYear().toString().padStart(4, 0),
                            (_collection[k].getMonth() + 1).toString().padStart(2, 0),
                            _collection[k].getDate().toString().padStart(2, 0)
                        ].join('-')
                    );
                }

                // Collection value is object ?
                else if (this.isObject(_collection[k])) {

                    try{

                        JSON.stringify( _collection[k] )
                        const v = _collection[k] ? parsedObjectToId(_collection[k]) : '';
                        // Just id
                        _formData.append(formattedKey, v );

                    } catch ( e ) { }
                    
                } else if (this.isArray(_collection[k])) {

                    _collection[k].map((collectionPart, index) => {

                        if (this.isObject(collectionPart)) {
                            _formData.append(formattedKey + '[' + index + ']', parsedObjectToId(collectionPart));
                        } else {
                            _formData.append(formattedKey + '[' + index + ']', collectionPart);
                        }

                    });

                } else if (this.isBoolean( _collection[k] )) {
                    const v = !!_collection[k];
                    _formData.append(formattedKey, v.toString() );
                }

                else {

                    _formData.append( formattedKey, _collection[k] );
                }

            }
        }

        else if ( this.isArray( _collection ) ){



        }

        if( null !== callbackFn && typeof callbackFn === "function"){
            callbackFn( _formData );
        }

        this._formData = _formData;

        return _formData;

        /// F I N I S H
    }


}
const parser = new AISCollectionParser();
export { parser as aisCollectionParser }


export class AISBackdropView {


    private _transparentBg: boolean = false;
    private _showIn?: HTMLElement;
    private _background: HTMLElement;
    private _parent: HTMLElement;
    private _view: HTMLElement;


    constructor(){
        this._parent = document.body;
    }


    init( transparentBg: boolean = false, parent: HTMLElement = document.body ){

        this._transparentBg = transparentBg;
        this._parent        = parent;

        let backdrop = document.createElement('div');
        backdrop.id = 'backdrop';
        backdrop.style.position = "absolute";
        backdrop.style.top = "0";
        backdrop.style.left = "0";
        backdrop.style.width = "100%";
        backdrop.style.height = "100%";
        backdrop.style.backgroundColor = "blue";
        backdrop.style.zIndex= "17891";

        this.setView(backdrop);

        return this;

    }

    show( callbackFn ){

        // if( this._showIn == null ){
        //     this._background.classList.add('show', 'fade');
        //     this._parent.appendChild(this._background);
        //
        // } else {
        //
        //     this._background.classList.add('position-absolute');
        //     this._showIn.style.position = 'relative';
        //     this._showIn.querySelectorAll('*').forEach( (item: HTMLElement) => {
        //         item.style.visibility = 'hidden';
        //     });
        //     this._showIn.appendChild(this._background);
        // }
        if( this._parent === null ){
            this._parent = document.body;
        }

        this._parent.appendChild( this.getView() );



        if( undefined !== callbackFn && typeof callbackFn === 'function' ){
            setTimeout(()=>{
                callbackFn( this );
            }, 300)
        }

    }




    dismiss(){
        this.getView().remove();
    }

    setView( el: HTMLElement ){
        this._view = el;
    }

    getView(){
        return this._view;
    }



    /**
     * @deprecated
     * @param elements {Array<HTMLElement|String>}
     * @param disable {Boolean}
     */
    disableAllEvents( elements, disable ){

        try{


            for(const element of elements){

                switch (typeof element) {
                    case 'string':
                        document.querySelectorAll(element).forEach( item => {
                            if(disable){
                                item.classList.add('disabled');
                            } else {
                                item.classList.remove('disabled');
                            }
                        });
                        break;
                    case 'object':
                        if(disable){
                            element.classList.add('disabled');
                        } else {
                            element.classList.remove('disabled');
                        }
                        break;
                }



            }


        } catch (e) {
            console.error('disableAllEvents', e);
        }

    }


}


export class AISDialogView {

    private _isInit = false;
    private _modal: HTMLElement;
    private _dialog: HTMLElement;
    private _content: HTMLElement;
    private _header: HTMLElement;
    private _body: HTMLElement;
    private _bodyContent: HTMLElement;
    private _bodyImage: HTMLElement;
    private _footer?: HTMLElement;
    private _actionsWrapper: HTMLElement = document.createElement('div');
    private _html: HTMLElement = document.getElementsByTagName("html")[0];
    private _htmlBody: HTMLElement = this._html.querySelector('body');
    private _title: string;
    private _titleEl: HTMLElement;
    private _appendTo?:HTMLElement;
    // private _loader = null;
    private _image?: AISImageView = null;
    private _formInfo: any;


    private _actions = [];

    constructor(){

        this._actionsWrapper = document.createElement('div');
        this._html = document.getElementsByTagName("html")[0];
        this._htmlBody = this._html.querySelector('body');

    }

    init(){

        this._isInit = true;
        // Create Wrapper
        this._modal = document.createElement('div');
        this._modal.classList.add('modal', 'fade' );
        this._modal.id = 'myModal';
        // this._modal.setAttribute('tabindex', '-1');
        this._modal.setAttribute('role', 'dialog');
        this._modal.setAttribute('aria-labelledby', 'aria-modal');
        this._modal.setAttribute('aria-hidden', 'true');

        // Dialog
        this._dialog = document.createElement('div');
        this._dialog.setAttribute('role', 'document');
        this._dialog.classList.add('modal-dialog');


        // Content
        this._content = document.createElement('div');
        this._content.classList.add('modal-content');

        // Title
        this._header = document.createElement('div');
        this._header.classList.add('modal-header');
        this._titleEl = document.createElement('h6');
        this._titleEl.classList.add('modal-title');
        this._titleEl.id = 'aria-modal';

        this._titleEl.innerText = this._title;
        this._header.appendChild(this._titleEl);

        // Body
        this._body = document.createElement('div');
        this._body.classList.add('modal-body', 'd-flex');

        // Body Content
        this._bodyContent = document.createElement('div');
        this._bodyContent.classList.add('body-content', 'd-flex');
        this._body.appendChild(this._bodyContent);

        // Body Image Init by setImage

        // Form Info
        this._formInfo = this.setFormInfo(null);

        // Loader
        // this._loader = new Loader();
        // this._loader.init();
        // this._loader.setSize(20);

        // Footer
        this._footer = document.createElement('div');
        console.log(this._footer);
        this._footer.classList.add('modal-footer');

    }

    initWithWidth(width){
        this.init();
        this._dialog.style.width = width+'px';
        return this;
    }


    /**
     *
     * @param imagePath {String}
     */
    public setImage( imagePath?: string){

        if( this._body.querySelector('.body-image') === null ){

            const modalImage = new AISImageView( imagePath );
            modalImage.setWidth(50);
            modalImage.setHeight(50);
            modalImage.render();
            modalImage.getView().classList.add('d-flex', 'body-image', 'm-2');
            modalImage.setSrc(imagePath);


            this._body.prepend(modalImage.getView());

            this._image = modalImage;

            return ;
        }

        this._image.setSrc(imagePath);
    }

    /**
     *
     * @return {ImageView}
     */
    private getImage() {
        return this._image;
    }

    public setTitle(title){
        this._title = title;
        this._titleEl.innerText = title;
        return this;
    }

    /**
     * @return HTMLElement
     */
    private getTitle(){
        return this._title;
    }

    public setBody(content){

        switch (typeof content) {
            case 'string':
                this._bodyContent.innerHTML = content;
                this._bodyContent.classList.add('align-content-center');
                break;

            case 'object':
                console.log('content',content.type.render);
                // componentWillAppendToBody(content);
                React.createElement(content, {});
                // this._bodyContent.appendChild(content.type.render);
                // this._bodyContent = content;
                break;

            default:
                alert('Unknown body type!');
        }

        return this;
    }

    private getBody(){
        return this._body;
    }

    public setMaxHeight(value){
        this._bodyContent.style.maxHeight = value + 'px';
        this._bodyContent.style.overflow = 'scroll';
        this._bodyContent.style.flexFlow = 'row';
    }

    public setFormInfo( infoString?: string ){

        this._formInfo = new class {

            private _text:string = 'Form Info';
            private _el: HTMLElement;

            constructor(infoString){

                if( undefined !== infoString ){
                    this._text = infoString;
                }

                this._el = document.createElement('small');
                this._el.id = 'special-info-id';
                this.setText('Info element');

            }

            show(){
                this._el.classList.add('d-block');
                this._el.classList.remove('d-none');
            }

            hide(){
                this._el.classList.remove('d-block');
                this._el.classList.add('d-none');
            }

            getView(){
                return this._el;
            }

            setText(string){
                this._text = string;
                this._el.innerHTML = this._text;
            }

            getText(){
                return this._text;
            }

        }(infoString);

    }

    private getFormInfo(){
        return this._formInfo;
    }

    public addActions( actions:Array<AISButtonView> ){
        for(const action of actions){
            this.addAction(action);
        }
    }

    private getActions(){
        return this._actions;
    }

    public addAction( action: AISButtonView ){
        this._actions.push(action);
    }

    public addAdditionalAction( action:AISButtonView, beforeAction?: AISButtonView){


        console.warn('Action index', this._actions.indexOf(action));
        if( this._actions.indexOf(action) > -1 ){
            return;
        }

        if( action !== undefined && action instanceof AISButtonView ){

            if( beforeAction !== null && beforeAction instanceof AISButtonView ){
                this._actions.splice( this._actions.indexOf(beforeAction), 0, action );
                this.renderActions();
                return;
            }

            this.addAction(action);
            this.renderActions();

        }
    }

    public removeAction(action: AISButtonView){
        this._actions.map(( item:AISButtonView ) => {

            if ( item === action ){
                console.warn('Found Action', );
                if( this._actions.indexOf(item) > -1){
                    this._actions.splice(this._actions.indexOf(item), 1);
                    item.setAction(null);
                    item.remove();
                    this._actions.slice(this._actions.indexOf(item), 1);
                }
            }

        });
    }

    public disabledAllActions( isDisabled = true ){
        for( const action of this._actions ){
            action.setDisabled(isDisabled);
        }
    }

    public appendTo(el){
        this._appendTo = el;
        return this;
    }

    private renderActions(){

        console.log('Rendered actions', this._actions);
        this._actionsWrapper.classList.add('modal-actions');

        this._actions.map(( item: AISButtonView ) => {
            if(!item.isRendered()){
            }
            this._actionsWrapper.appendChild(item.getView());
            item.render();
        });
    }


    public show( callbackFn: Function ) {


        return (
            <div>Selam</div>
        );



    }


    show_( callbackFn ){

        const backdrop = new AISBackdropView();
        backdrop
            .init(false, null)
            .show( backdrop => {


                if(!this._isInit)
                    alert('Modal init not requested!');

                if( this._body === null ){
                    alert( "Modal haven't body " );
                    return ;
                }

                this.renderActions();

                this._modal.appendChild(this._dialog);
                this._dialog.appendChild(this._content);

                if( null !== this._header )
                {
                    this._content.appendChild(this._header);
                }

                // Add Image
                if( this._image !== null && undefined !== this._image && this._image instanceof AISImageView ){

                    this._body.prepend(this._image.getView());

                    const modalImage = this._body.querySelector('.body-image');
                    console.log(modalImage);
                }

                this._content.appendChild(this._body);

                if( null !== this._footer ){
                    this._content.appendChild(this._footer);
                }

                // Append Modal to Body
                // this._html.appendChild(this._modal);
                // this._htmlBody.appendChild(this._modal);
                //
                // console.warn('this._appendTo', this._appendTo);
                //
                // if( undefined !== this._appendTo && this._appendTo instanceof HTMLElement )
                // {
                //     this._appendTo.appendChild(this._modal);
                //     return;
                // }

                backdrop.getView().appendChild( this._modal );

                if( undefined !== this._appendTo && this._appendTo instanceof HTMLElement )
                {
                    this._appendTo.appendChild(backdrop.getView());
                    return;
                }



                // this._loader.insertTo(this._footer);

                // Add Actions
                this._footer.appendChild( this._actionsWrapper );

                // Form Info
                // this._bodyContent.appendChild(this.getFormInfo().getView());
                // this.getFormInfo().hide();



                // $(this._modal).modal({
                //     backdrop: 'static',
                //     keyboard: false
                // });
                //
                //
                // $(this._modal).find('[data-toggle="tooltip"]').tooltip();
                // $(this._modal).find('[data-toggle="popover"]').popover();
                //
                // $('body').on('click', function (e) {
                //     //did not click a popover toggle or popover
                //     if ($(e.target).data('toggle') !== 'popover'
                //         && $(e.target).parents('.popover.in').length === 0) {
                //         $('[data-toggle="popover"]').popover('hide');
                //     }
                // });

                if( undefined !== callbackFn && typeof callbackFn === 'function' ){
                    callbackFn( this );
                    // setTimeout(function () {
                    // }, 300 );
                }



            });



    }

    // dismiss(callbackFn){
    //     $(this._modal).modal('hide');
    //     setTimeout(()=>{
    //         this._modal.remove();
    //         if( typeof callbackFn === "function"){
    //             callbackFn(this);
    //         }
    //     },300)
    //
    // }
    //
    // loader() {
    //     this._loader.position('start', 'center');
    //     this._loader.view().style.paddingLeft = '.7rem';
    //     return this._loader;
    // }
    //
    // getLoader(){
    //     return this.loader();
    // }

}

export class AISImageView{

    private _width:number;
    private _height:number;
    private _src:string;
    private _el:HTMLImageElement;
    private _addAppendTo:HTMLElement;

    /**
     *
     * @param el {HTMLElement}
     */
    public addAppendTo(el) {
        this._addAppendTo = el;
    }

    public setSrc(value){
        this._src = value;
        this._el.src = this._src;
    }

    private getSrc(){
        return this._src;
    }

    public setWidth(value){
        this._width = value;
    }
    private getWidth(){
        return this._width;
    }

    public setHeight(value){
        this._height = value;
    }
    private getHeight(){
        return this._height;
    }

    constructor( src?: string ){

        if( undefined === src ){
            console.error('Bitte geben Sie eine gültige image URL');
            return;
        }
        this.setWidth(100);
        this.setHeight(100);
        this._src = src;
        this.addAppendTo(document.getElementsByTagName('body')[0]) ;
        this._el = null;

    }

    render(){

        let el = document.createElement('img');
        el.src = this.getSrc();
        el.width = this.getWidth();
        el.height = this.getHeight();
        this._addAppendTo.appendChild(el);

        this._el = el;

    }

    getView(){
        return this._el;
    }

}

export class AISButtonView{

    private _id: string;
    private _action: Function;
    private _style: string = 'default' ;
    private _view: HTMLButtonElement;
    private _modal: AISDialogView;
    private _rendered = false;

    private _title: string = 'Button Title';
    private _buttonInlineElement: HTMLElement;


    public setId(value){
        this._id = value;
    }

    private getId(){
        return this._id;
    }


    setAction( value ){
        this._action = value;
        return this;
    }


    public setStyle(value){
        this._style = value;
        this._view.removeAttribute('class');
        const style =  'btn-' + this._style;
        this._view.classList.add('btn', 'text-nowrap', style, 'btn-sm' );
        return this;
    }

    private getStyle(){
        return this._style;
    }

    private setModal(modal){
        this._modal = modal;
        return this;
    }

    private getTitle(){
        return this._title;
    }


    public setTitle(title){
        this._title = title;
        this._buttonInlineElement.innerText = this._title;
        this._view.appendChild(this._buttonInlineElement);
        return this;
    }

    public getView(){
        return this._view;
    }

    private setView(el: HTMLButtonElement ){
        this._view = el;
    }

    public remove(){
        this.getView().remove();
    }

    public setDisabled(value){
        if(value){
            this.getView().classList.add('disabled');
            this.getView().setAttribute('disabled', 'disabled');
            return ;
        }
        this.getView().classList.remove('disabled');
        this.getView().removeAttribute('disabled');
    }


    setEnabled(value){
        this.setDisabled(!value);
    }

    click(){
        this._view.click();
    }

    isRendered(){
        return this._rendered;
    }



    constructor( modal: AISDialogView ){

        this.setView(document.createElement('button'));
        this._buttonInlineElement = document.createElement('span');
        this.setModal(modal);

    }



    render(){

        this.getView().type = 'button';

        try{

            if( !this.isRendered() ){
                this._view.addEventListener('click', ()=>{
                    if( undefined !== this._action() && null !== this._action() && typeof this._action() === 'function' ){
                        this._action( this, this._modal );
                    }
                });
                this._rendered = true;
            }

        } catch (e) {
            console.error('Modal Action render', e.title);
        }




    }













}









